<template>

  <section>
    <iq-card body-class="p-0">
      <template v-slot:body>
        <!-- 头像 -->
        <div class="user-post-data d-flex justify-content-between p-3">
          <div @click="
                      $router.push({
                        path: '/profile',
                        query: { user_id: post.user_id },
                      })
                    " class="d-flex flex-wrap">
            <div class="user-img mr-3">
              <el-avatar fit="cover" :size="60" :src="post.avatar">
                <img src="../../../assets/images/public/user.png" />
              </el-avatar>
              <!-- <el-avatar :size="60" :srcSet="post.avatar,"></el-avatar> -->
            </div>
            <div class="media-support-info mt-2">
              <h5 class="mb-0 d-flex">
                <b-link href="javascript:void(0)" style="
                            width: 20vh;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          ">{{ post.nick_name }}</b-link>
                <div v-if="isPostType && post.type == 2" class="user_post_type"
                  @click.stop="$router.push(`/group-detail?id=${post.type_id}`)">
                  {{ $t("Posts.d2b@post_from_interest_c")
                  }}
                  <div :title="post.type_name">[{{ post.type_name }}]</div>
                </div>
              </h5>
              <!-- <span v-if="post.advertising_type == 2" class="ad-flag">{{$t("Posts.8cb@advertisement")}}</span> -->
              <span v-if="post.advertising_type == 2&&post.tag_display==1" class="ad-flag">Sponsored</span>
              <p v-if="post.advertising_type != 2" class="mb-0 text-secondary">
                {{ post.created_at }}
              </p>
            </div>
          </div>
          <div class="iq-card-post-toolbar">
            <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
              <template v-slot:button-content>
                <i class="ri-more-fill"></i>
              </template>
              <b-dropdown-item v-if="post.is_can_share" class="dropdown-item p-0">
                <h6 class="pt-2 pb-2" v-clipboard:copy="computedCopyLink" v-clipboard:success="copyPostLink">
                  {{ $t("Posts.ee0@copy_link") }}
                </h6>
              </b-dropdown-item>
              <b-dropdown-item class="dropdown-item p-0">
                <h6 class="pt-2 pb-2" @click="handleClickFriendsPopup(post)">
                  {{ $t("Posts.ca3@share_with_friends") }}
                </h6>
              </b-dropdown-item>
              <b-dropdown-item class="dropdown-item p-0" v-if="post.advertising_type == 1&&post.user_id != selfId">
                <h6 class="pt-2 pb-2" @click="getShareInfo(post)">
                  Report Spam
                </h6>
              </b-dropdown-item>
              <b-dropdown-item class="dropdown-item p-0" v-if="post.advertising_type == 1&&post.user_id != selfId">
                <h6 class="pt-2 pb-2" @click="hidePosts(post)">
                  Hide
                </h6>
              </b-dropdown-item>
              <b-dropdown-item class="dropdown-item p-0" v-if="post.advertising_type == 1&&post.user_id != selfId">
                <h6 class="pt-2 pb-2" @click="pullBlack(post)">
                  Block
                </h6>
              </b-dropdown-item>
              <b-dropdown-item class="dropdown-item p-0" v-show="post.user_id == selfId&&post.advertising_type == 1">
                <h6 class="pt-2 pb-2" @click="$bus.$emit('editPost', post.id)">
                  Edit
                </h6>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <section v-if="post.is_show && !post.is_show" class="pl-3 pr-3 violation_post">
          <p>{{ $t("Posts.8b5@this_post_will_not_b") }}</p>
        </section>

        <!-- content -->
        <section v-else class="pl-3 pr-3">
          <!-- 帖子内容/@/查看图片-->
          <div class="user_post_content" v-if="post.id">
            <div class="d-flex flex-wrap align-items-center">
              <!-- 广告帖子的内容 -->
              <section v-if="post.advertising_type == 2">
                <section v-html="post.content" class="ad_content"></section>
                <p class="m-0 ">
                  <a v-if="post.ad_link" :href="post.ad_link" target="_blank" @click="clickAd(post.id)"><i
                      class="ri-link-m"></i>{{
                    post.ad_title }}</a>
                </p>
              </section>
              <p v-else v-html="postContentFilter(post.content)" ref="postContent" class="m-0"></p>

              <!-- “查看图片” -->
              <ul v-if="post.share_id && post.media.length" class="simple_img d-flex p-0 m-0">
                <!-- v-for="(src, index) in post.media" :key="index" -->
                <li class="pr-3">
                  <i class="ri-image-line"></i>
                  <span @click="overViewPicture(post)">{{
                    $t("Posts.a35@view")
                    }}</span>
                </li>
              </ul>
              <!--@Friends -->
              <ul class="m-0 p-0 d-flex flex-wrap">
                <li v-for="(item, index) in post.friends" :Key="index">
                  <router-link :to="{ path: '/profile', query: { user_id: item.user_id } }" class="mr-2" href="">@{{
                    item.nick_name }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <!-- MediaList -->
          <div class="post_media_list" v-if="!post.share_id">
            <div class="media_item"
              :class="{'img_cover':image.type == 'photo','one_img':post.media.length==1,'four_img':post.media.length==4,'common_img':post.media.length!=1&&post.media.length!=4}"
              v-for="(image, index) in post.media" :key="index" @click="getPostDetail(post, image, index)">
              <el-image v-if="image.type == 'photo'" :src="image.url" fit="cover">
                <div slot="error" class="image-slot">
                  <img src="../../../assets/images/public/fail.png" />
                </div>
              </el-image>
              <video preload controls v-else :src="image.url"></video>
            </div>
          </div>

          <!-- 转发的原帖子被删除 -->
          <div v-if="post.share_type && post.share_id == null" class="original_post_del mt-2">
            <p class="m-0">{{ post.share_type | filterShareType }}</p>
          </div>

          <!-- share 帖子 -->
          <div v-if="post.share_type == 1 && post.share_id" class="share_link" style="padding: 15px;">
            <div class="share_avatar d-flex align-items-center mb-2">
              <el-avatar fit="cover" :size="40" :src="post.share_id.avatar" @error="()=>{return true}">
                <img src="../../../assets/images/public/user.png" />
              </el-avatar>
              <h3 class="m-0">
                {{ post.share_id.nick_name }}
              </h3>
            </div>
            <p class="m-0" v-if="post.advertising_type == 2">
              {{ post.share_id.content }}
              <a :href="post.share_id.ad_link" target="_blank" @click="clickAd(post.share_id.id)"><i
                  class="ri-link-m"></i>{{ post.share_id.ad_title }}</a>
            </p>
            <p v-else v-html="postContentFilter(post.share_id.content)" ref="postContent" class="m-0"></p>

            <!-- share——media -->
            <ul class="p-0 media_box">
              <li v-for="(shareMeida, index) in post.share_id.media" :key="index"
                :class="{'one_share':post.share_id.media.length==1,'four_share':post.share_id.media.length==4,'common_share':post.share_id.media.length!=1&&post.share_id.media.length!=4}">
                <!-- @click="overViewPicture(shareMeida)" -->
                <img class="img_box" v-if="shareMeida.type == 'photo'" :src="shareMeida.url" alt="" />
                <video controls :src="shareMeida.url" v-else></video>
              </li>
            </ul>

            <div class="
                        share_icon_list
                        d-flex
                        align-items-center
                        justify-content-between
                      ">
              <p class="m-0 p-0">{{ post.share_id.created_at }}</p>
              <ul class="p-0 m-0 d-flex">
                <li>
                  <i class="ri-share-line" @click="shareStaus(post.share_id)"></i>
                  <span @click="toPostDetail(post.share_id)">{{ post.share_id.share_total }}</span>
                </li>
                <li @click="toPostDetail(post.share_id)">
                  <i class="ri-message-3-line"></i><span>{{ post.share_id.comment_total }}</span>
                </li>
                <li :class="post.share_id.user_post_like ? 'text-primary' : ''">
                  <i class="ri-thumb-up-line" @click="likePost(post.share_id)"></i>
                  <span @click="toPostDetail(post.share_id)">{{ post.share_id.like_total }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 分享课程、项目、活动 -->
          <div v-else-if="post.share_type != 1 && post.share_id"
            class="share_link rightTopBtn d-flex align-items-center">
            <!-- <img :src="post.share_id.image" alt="" v-if="post.share_id" /> -->
            <el-image :src="post.share_id.image" fit="cover" style="width: 100px; height: 100px" v-if="post.share_id">
              <div slot="error" class="image-slot">
                <img src="@/assets/images/public/kong_img.png" style="width: 78px; height: 60px; margin: 20px 10px" />
              </div>
            </el-image>
            <div class="typeBtn">
              <span v-if="post.share_type == 7">{{
                $t("Headbar.439@award")
                }}</span>
              <span v-if="post.share_type == 4">{{
                $t("Headbar.c60@event")
                }}</span>
              <span v-if="post.share_type == 5">{{
                $t("Headbar.015@project")
                }}</span>
              <span v-if="post.share_type == 61">
                <!-- {{$t("Headbar.08a@channel")}} -->
                Organization
              </span>
              <span v-if="post.share_type == 2">{{
                $t("Headbar.a4d@meeting")
                }}</span>
              <span v-if="post.share_type == 3">{{
                $t("Headbar.c5c@course")
                }}</span>
              <span v-if="post.share_type == 8">
                Point product
              </span>
            </div>

            <div v-if="post.share_id != undefined" class="w-100 share_content pr-2" style="margin-left: 10px;flex: 1;">
              <h5 class="cursor-pointer" @click="currentShareShowType('url', post)">
                {{ post.share_id.title }}
              </h5>
              <div class="w-100 d-flex justify-content-between">
                <span class="m-0 d-flex align-items-end">{{
                  post.share_id.start_time
                  }}</span>
                <div class="m-0 d-flex justify-content-end text-right" v-if="post.share_id.address">
                  <i class="ri-map-pin-line mr-2" style="font-size: 20px"></i>
                  <span class="d-flex align-items-center">
                    {{ post.share_id.address }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- location -->
          <div class="m-0" v-if="post.location" style="color: #999">
            <i class="ri-map-pin-line"></i>{{ post.location }}
          </div>

          <!-- like、comment、share -->
          <ul class="like_comment_share p-0 m-0 mt-2" v-if="post.is_admin">
            <li class="bg_gray">
              <i @click="deletePost(post)" class="ri-delete-bin-line"></i>
            </li>
            <li class="bg_gray" v-if="!post.share_id">
              <i @click="shareStaus(post)" class="ri-share-line"></i>
              <span @click="checkSharePost(post)">{{ post.share_total }} <span class="pcText">Share</span></span>
            </li>
            <li class="bg_gray" :class="post.isShowCommentList ? 'text-primary' : ''">
              <i @click="getFirstCommentList(post)" class="ri-message-3-line"></i>
              <span @click="getFirstCommentList(post)">{{ post.comment_total }} <span class="pcText">Comment</span></span>
            </li>
            <li class="bg_gray" :class="{ 'text-primary': post.user_post_like }">
              <i @click="likePost(post)" class="ri-thumb-up-line"></i>
              <span @click="checkLikePost(post)">{{ post.like_total }} <span class="pcText">Likes</span></span>
            </li>
          </ul>
          <ul class="like_comment_share p-0 m-0 mt-2" v-else>
            <li class="bg_gray" v-if="post.is_user_send">
              <i @click="deletePost(post)" class="ri-delete-bin-line"></i>
            </li>
            <li class="bg_gray" v-if="!post.is_user_send && !post.share_id">
              <i @click="shareStaus(post)" class="ri-share-line"></i>
              <span @click="checkSharePost(post)">{{ post.share_total }} <span class="pcText">Share</span></span>
            </li>
            <li class="bg_gray" :class="post.isShowCommentList ? 'text-primary' : ''">
              <i @click="getFirstCommentList(post)" class="ri-message-3-line"></i>
              <span @click="getFirstCommentList(post)">{{ post.comment_total }} <span class="pcText">Comment</span></span>
            </li>
            <li class="bg_gray" :class="{ 'text-primary': post.user_post_like }">
              <i @click="likePost(post)" class="ri-thumb-up-line"></i>
              <span @click="checkLikePost(post)">{{ post.like_total }} <span class="pcText">Likes</span></span>
            </li>
          </ul>
          <div v-if="likePostShow">
            <likePost :id="post.id"></likePost>
          </div>
          <div v-if="sharePostShow">
            <sharePost :id="post.id"></sharePost>
          </div>
          <!-- 顶级评论 -->
          <div v-if="post.isShowCommentList">
            <!-- 大评论框 -->
            <!-- 评论的图片列表 -->
            <ul class="p-0 m-0 media_box">
              <li class="li_pic" v-for="(item, index) in commentMedia" :key="index">
                <img v-if="item.type == 'photo'" :src="item.url" alt="" />
                <video v-else :src="item.url" controls autoplay></video>
                <!-- <img class="close_img" src="../../../assets/images/add_post_close.png" @click="unshiftMedia(index)" alt="" /> -->
                <img class="close_img" src="../../../assets/images/add_post_close.png"
                  @click="commentMedia.splice(index, 1)" alt="" style="cursor: pointer" />
                <!-- <i class="las la-times-circle" ></i> -->
              </li>
            </ul>
            <b-form @submit.prevent class="comment-text d-flex align-items-center pb-3">
              <b-form-input type="text" v-model="commentPostContent" class="rounded" />
              <!-- @keyup.enter.native="commentPost(post)" -->
              <div class="comment-attagement d-flex">
                <el-upload class="avatar-uploader" action="no" :http-request="imgUpload" :show-file-list="false"
                  :before-upload="beforeAvatarUpload">
                  <i class="ri-camera-line mr-2"></i>
                </el-upload>
                <button :disabled="!commentPostContent && !commentMedia.length" class="btn btn-primary"
                  @click="commentPost(post)">
                  {{ $t("Posts.a98@send") }}
                </button>
              </div>
            </b-form>

            <!-- 顶级评论 -->
            <div class="comment_and_replay pb-2" style="overflow:auto;max-height:300px;" ref="commentRef">
              <!-- infinite-scroll-disabled="disabled"
                        infinite-scroll-delay="500"
                        :infinite-scroll-immediate="infiniteDisabled"
                        infinite-scroll-distance="0"
                        v-infinite-scroll="InfinitePostData(post)"  -->
              <div class="d-flex justify-content-between align-items-top" v-for="postComment in post.firstCommentList"
                :key="postComment.id">
                <div class="user_avatar mr-2">
                  <!-- <img :src="postComment.avatar" alt="" /> -->
                  <el-avatar :size="35" :src="postComment.avatar" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="comment_content">
                  <div class="comment_main">
                    <span>{{ postComment.nick_name }}</span>：
                    {{ JSON.parse(postComment.content).content }}
                    <ul v-if="JSON.parse(postComment.content).img.length" class="simple_img d-flex p-0 m-0">
                      <li class="pr-3">
                        <i class="ri-image-line"></i>
                        <span @click="overViewPicture(postComment.content)">{{
                          $t("Posts.a35@view")
                          }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="time_icon">
                    <span>{{ postComment.created_at }}</span>
                    <ul class="m-0 p-0 d-flex">
                      <li v-show="postComment.is_user_send" @click="delSelfPostComment(post, postComment)">
                        <i class="ri-delete-bin-line"></i>
                      </li>
                      <li @click="openReplayComment(postComment)">
                        <i class="ri-message-3-line"></i>
                      </li>
                      <li :class="{
                                  'text-secondary': !postComment.is_like,
                                  'text-primary': postComment.is_like,
                                }" @click="likePostComment(postComment)">
                        <i class="ri-thumb-up-line"></i>
                        <em style="
                                    font-style: normal;
                                    min-width: 18px;
                                    display: inline-block;
                                    text-align: right;
                                  ">{{ postComment.like_total }}</em>
                      </li>
                    </ul>
                  </div>

                  <!-- 二级评论列表入口---gmc等人共8条回复-->
                  <div v-if="postComment.comment_total" class="second_comment">
                    <span class="mr-1">{{
                      postComment.comment_user_name
                      }}</span>
                    Et
                    <span class="ml-1" @click="secondCommentOpen(postComment)">all {{ postComment.comment_total }}
                      reply</span>
                    <i class="ri-arrow-down-s-fill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </iq-card>

    <!-- complain -->
    <b-modal :id="`complainModalId${post.id}`" ref="complainModalId" centered title="Complaint" hide-footer scrollable>
      <div class="complain_box">
        <h3>Complaint of undesirable content</h3>
        <div class="complain_content">
          <div v-if="isHasImg" class="img_box" style="width: 80px">
            <img v-if="compalinInfoType == 'photo'" :src="compalinInfoeUrl" style="object-fit: cover" />
            <video v-else :src="compalinInfoeUrl"></video>
          </div>
          <div style="width: calc(100% - 80px)" class="share_content">
            <span>@{{ compalinInfo.nick_name }}</span>
            <p class="m-0">{{ compalinInfo.content }}</p>
          </div>
        </div>
        <h3>Please select the reason for the complaint</h3>
        <ul class="p-0 m-0 radia_choose">
          <li :style="{
                      'background-color':
                        index == chooseComplainType ? '#f5fbff' : '#F4F5F6',
                      color: index == chooseComplainType ? '#50B5FF' : '#333',
                    }" v-for="(complainType, index) in complainTypeList" :key="index"
            @click="choosedOneComplain(index)">
            {{ complainType }}
          </li>
        </ul>

        <b-form-textarea id="textarea" placeholder="Describe..." v-model="describe_textarea" rows="2" max-rows="6"
          class="mt-3">
        </b-form-textarea>

        <hr class="mt-5" />
        <footer class="d-flex justify-content-end">
          <b-button variant="primary" @click="complainPost(post)">{{
            $t("MyJCI.f2c@submit")
            }}</b-button>
        </footer>
      </div>
    </b-modal>

    <!-- SecondList -->
    <b-modal :id="replayListModalId" centered :title="replayModalTitle" hide-footer scrollable>
      <!-- 父级评论 -->
      <ul class="comment_and_replay p-0 m-0">
        <li>
          <div class="d-flex justify-content-between align-items-top">
            <div class="user_avatar mr-2">
              <!-- <img :src="parentcomment.avatar" alt="" /> -->
              <el-avatar :size="35" :src="parentcomment.avatar" @error="true">
                <img src="@/assets/images/public/user.png" />
              </el-avatar>
            </div>
            <div class="comment_content">
              <div class="comment_main d-flex">
                <span>{{ parentcomment.nick_name }}</span>：
                <div class="m-0 d-flex">
                  {{
                  parentcomment.content &&
                  JSON.parse(parentcomment.content).content
                  }}
                  <ul v-if="
                              parentcomment.content &&
                              JSON.parse(parentcomment.content).img.length
                            " class="simple_img d-flex p-0 m-0">
                    <li class="pr-3">
                      <i class="ri-image-line"></i>
                      <span @click="overViewPicture(parentcomment.content)">{{
                        $t("Posts.a35@view")
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="time_icon">
                <span>{{ parentcomment.created_at }}</span>
                <ul class="m-0 p-0">
                  <li @click="parentcomment.isShowReplayForm = true">
                    <i class="ri-message-3-line"></i>
                  </li>
                  <li :class="{
                              'text-secondary': !parentcomment.is_like,
                              'text-primary': parentcomment.is_like,
                            }" @click="likePostComment(parentcomment)">
                    <i class="ri-thumb-up-line"></i>{{ parentcomment.like_total }}
                  </li>
                </ul>
              </div>
              <div v-if="parentcomment.isShowReplayForm" class="replay_white_box">
                <input v-model="parentcomment.replayForm" :placeholder="$t('Posts.e80@reply')" type="text" />
                <div class="mt-2">
                  <b-button @click="
                              replayModalComment(
                                parentcomment.user_post_id,
                                parentcomment,
                                parentcomment.replayForm,
                                post
                              )
                            " variant="primary" size="sm">{{ $t("Posts.e80@reply") }}</b-button>
                </div>
              </div>
            </div>
          </div>

          <!-- 二级评论详细内容 -->
          <ul class="p-0">
            <li v-for="second in secondCommentList" :key="second.id">
              <div class="second_comment d-flex">
                <span class="mr-1">{{ second.nick_name }}</span>
                <p class="m-0" v-if="second.parent != 1">
                  Reply<span class="text-primary ml-1">{{
                    second.parent.nick_name
                    }}</span>
                </p>
                :
                <p class="m-0" v-if="
                            second.content &&
                            !JSON.parse(second.content).content &&
                            !JSON.parse(second.content).img
                          ">
                  {{ second.content }}
                </p>

                <div class="m-0 d-flex">
                  {{ second.content && JSON.parse(second.content).content }}
                  <ul v-if="
                              second.content &&
                              JSON.parse(second.content).img &&
                              JSON.parse(second.content).img.length
                            " class="simple_img d-flex p-0 m-0">
                    <li class="pr-3">
                      <i class="ri-image-line"></i>
                      <span @click="overViewPicture(second.content)">{{
                        $t("Posts.a35@view")
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="
                          second_time
                          d-flex
                          justify-content-between
                          align-items-center
                        ">
                <span>{{ second.created_at }}</span>
                <ul class="m-0 p-0 d-flex second_icon">
                  <li v-if="second.is_user_send" @click="deleteModalComment(second, post)">
                    <i class="ri-delete-bin-line"></i>
                  </li>
                  <li @click="second.isShowReplayForm = true">
                    <i class="ri-message-3-line"></i>
                  </li>
                  <li :class="{
                              'text-secondary': !second.is_like,
                              'text-primary': second.is_like,
                            }" @click="likePostComment(second)">
                    <i class="ri-thumb-up-line"></i>
                    {{ second.like_total }}
                  </li>
                </ul>
              </div>
              <div v-if="second.isShowReplayForm" class="left_padding_white_box">
                <input v-model="second.replayForm" :placeholder="$t('Posts.e80@reply')" type="text" />
                <div class="mt-2">
                  <b-button @click="
                              replayModalComment(
                                second.user_post_id,
                                second,
                                second.replayForm,
                                post,
                                true
                              )
                            " variant="primary" size="sm">{{ $t("Posts.e80@reply") }}</b-button>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </b-modal>

    <!-- second Comment input -->
    <b-modal :id="repalyFormModalId" centered :title="textareaModalTitle" hide-footer>
      <el-upload class="avatar-uploader" action="no" :http-request="imgUploadEr" :show-file-list="false">
        <i class="ri-camera-line mr-2" style="font-size: 30px"></i>
      </el-upload>

      <!-- 评论的图片列表 -->
      <ul class="p-0 m-0 media_box">
        <li class="li_pic" v-for="(item, index) in commentMediaEr" :key="index">
          <img v-if="item.type == 'photo'" :src="item.url" alt="" />
          <video v-else :src="item.url" controls autoplay></video>
          <img class="close_img" src="../../../assets/images/add_post_close.png"
            @click="commentMediaEr.splice(index, 1)" alt="" style="cursor: pointer" />
        </li>
      </ul>

      <b-form-textarea id="textarea" :placeholder="$t('Posts.5ff@enter_something')" v-model="replay_textarea" rows="3"
        max-rows="6">
      </b-form-textarea>

      <b-button @click="replayPostComment(post)" class="mt-2" block variant="primary">{{ $t("Posts.e80@reply") }}
      </b-button>
    </b-modal>

    <!-- PostDetail -->
    <selfModal v-if="isShowSelfModal" style="width: 100%; height: 100%; position: fixed; top: 0; left: 0"
      @close="closeSelfModal" :postDetail="post" :mediaIndex="mediaIndex" />

    <!-- view-picture -->
    <viewPicture v-if="isShowViewPicture" :mediaListProp="viewPictureImg" @closeViewPhoto="isShowViewPicture = false"
      style="width: 100%; position: fixed; top: 0; left: 0; z-index: 9999" />

    <!-- 分享到群和好友 -->
    <b-modal :id="friendOrGroupPopup()" centered modal-class="friendOrGroupPopup"
      :title="$t('Posts.297@share_friend_or_grou')" hide-footer>
      <div class="row m-0 h-100 friendOrGroupPopup">
        <div class="col-5 contentLeft border-right">
          <!-- 搜索框 -->
          <!-- <div class="mb-3 d-flex flex-grow-1 searchBox">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Group"
                    />
                    <img src="@/assets/images/course/search.png" alt="" />
                  </div> -->
          <!-- 好友列表 -->
          <ul class="p-0">
            <li class="d-flex justify-content-between mb-3" v-for="(item, index) in friendAndGroup" :key="index">
              <div class="d-flex align-items-center">
                <div class="imgBox">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="name textOverflow" v-if="item.user_id">
                  {{ item.nick_name }}
                </div>
                <div class="name textOverflow" v-else>
                  {{ item.group_name }}
                </div>
              </div>
              <div class="d-flex align-items-center">
                <input type="checkbox" class="form-check-input mr-3 mb-1" v-model="item.select" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col-7 d-flex flex-column justify-content-between contentRight">
          <div>
            <div class="title mb-3">
              {{ $t("Posts.c24@please_check_the_con") }}
            </div>
            <!-- 选中的添加好友 -->
            <ul class="p-0">
              <li class="mb-3 d-flex justify-content-between" v-for="(item, i) in filterSelectFriend" :key="i">
                <div class="d-flex align-items-center">
                  <div class="imgBox">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <div class="name textOverflow" v-if="item.user_id">
                    {{ item.nick_name }}
                  </div>
                  <div class="name textOverflow" v-else>
                    {{ item.group_name }}
                  </div>
                </div>
                <div class="d-flex align-items-center cursor-pointer" @click="removeSelect(item)">
                  <img src="@/assets/images/public/close-s.png" alt="" />
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" class="mr-3" @click="sendMessages">{{
              $t("Posts.fca@confirm")
              }}</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </section>

</template>
<script>
  import bus from '@/Utils/Bus'
  import selfModal from '@/components/ComComponents/selfModal'
  import viewPicture from '@/components/ComComponents/previewPicture'
  import likePost from './component/likePost'
  import sharePost from './component/sharePost'

  import { mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'SocialPost',
    props: {
      friendsList: {
        type: Array
      },
      post: {
        type: Object,
        default: {}
      },
      pageType: {
        type: String,
        default: ''
      },
      isShowViewAllComment: {
        type: Boolean,
        default: true
      },
      isPostType: {
        type: Boolean,
        default: false
      }
    },

    components: { selfModal, viewPicture, likePost, sharePost },

    data() {
      return {
        commentPostContent: '',
        replay: '',
        childCommentList: [],
        isShowReplay: false,

        // share
        friendsInfoList: [],
        sharePostInfomation: {},

        // viewPicture
        isShowViewPicture: false,
        viewPictureImg: '',

        // selfModal
        isShowSelfModal: false,
        postDetail: {},

        // 回复弹框
        firstCommentPage: 0,
        replay_textarea: '',
        // 传递的comment信息
        firstCommentObj: {},
        // firstCommentList: [],
        // 二级评论弹框
        parentcomment: {},
        secondCommentList: [],
        commentListParent: {},
        // compain
        isHasImg: 0,
        compalinInfo: {},
        compalinInfoeUrl: '',
        compalinInfoType: '',
        complainTypeList: ['Fraud', 'Violence', 'Vulgar', 'Crime', 'Other'],
        chooseComplainType: 0,

        // friendsList: [],
        // 好友 和 群组
        friendAndGroup: [],
        // 分享好友 or 群  = > 选中
        selectedList: [],
        // 要分享的内容
        shareData: {},

        // 评论图片列表
        commentMedia: [],

        commentMediaEr: [],
        describe_textarea: '',

        commonPage: 1, // 帖子评论分页
        commonTotal: 0, // 评论总数

        sharePostShow: false,
        likePostShow: false
      }
    },
    filters: {
      filterShareType(val) {
        let type
        switch (val) {
          case 1: {
            type = 'Post'
            break
          }
          case 2: {
            type = 'Meeting'
            break
          }
          case 3: {
            type = 'Course'
            break
          }
          case 4: {
            type = 'Activity'
            break
          }
          case 5: {
            // type = "Project"
            type = 'Event'
            break
          }
          case 6: {
            type = 'Post'
            break
          }
          case 7: {
            type = 'Program'
            break
          }
          case 8: {
            type = 'Point product'
            break
          }
          case 61: {
            type = 'Channel post'
            break
          }
        }
        return `Sorry, this ${type} has been deleted by the author.`
      }
    },

    computed: {

      // 获取当前用户相关信息
      ...mapGetters({
        dataChat: 'ChatRoom/getDataChat'
      }),
      computedFriendModalId() {
        return `choosedFriendsModalId${this.post.id}`
      },
      // 为post添加'是否显示顶级评论+顶级评论列表'
      computedPost() {
        this.$set(this.post, 'isShowCommentList', false)
        this.$set(this.post, 'firstCommentList', [])

        if (this.post.share_id) {
          this.$set(this.post, '', [])
        }
      },
      replayModalTitle() {
        return `${this.secondCommentList.length} Reply`
      },

      textareaModalTitle() {
        return `Reply ${this.firstCommentObj.nick_name}`
      },

      repalyFormModalId() {
        return `commentForm${this.firstCommentObj.id}`
      },

      replayListModalId() {
        return `secondCommentListModal${this.commentListParent.id}`
      },

      computedPageType() {
        return this.pageType == 'home' ? 1 : this.pageType == 'group' ? 2 : 3
      },
      // 过滤出选中的好友
      filterSelectFriend() {
        let friendsList = []
        this.friendAndGroup.forEach((v, i) => {
          if (v.select) {
            friendsList.push(v)
          }
        })
        this.selectedList = friendsList
        return friendsList
      },

      computedCopyLink() {
        // return `${baseUrl}/#/newsfeeds/${this.post.info_url}`
        return `${window.location.href}/${this.post.info_url}`
      }
    },
    created() {
      this.selfId = JSON.parse(this.$getlocalStorage('userInfo')).user_id
    },

    mounted() {
      // 监听键盘按键事件
      let self = this
      this.$nextTick(function () {
        document.addEventListener('keyup', function (e) {
          if (e.keyCode == 27) {
            self.isShowViewPicture = false
            self.isShowSelfModal = false
          }
        })
      })
    },

    methods: {
      // 隐藏帖子
      hidePosts(post) {
        this.$confirm('Are you sure you want to hide this post?', 'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(async () => {
          let res = await this.$http.hidePosts({ posts_id: [post.id] })
          if (res.status == 200) {
            this.$emit('freshHomePost')
          }
        })
      },

      // 拉黑帖子作者
      pullBlack(post) {
        this.$confirm('Are you sure you want to add this post to the blocklist?', 'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(async () => {
          let res = await this.$http.pullBlack({ blacks_user_id: [post.user_id] })
          if (res.status == 200) {
            this.$emit('freshHomePost')
            this.$message.success(res.message)
          }
        })
      },

      beforeAvatarUpload(file) {
        // console.log(file)
        const isJPG = file.type
        let arrType = isJPG.split('/')
        // console.log(arrType)
        if (arrType[0] != 'image') {
          this.$message.warning(this.$t('Posts.d80@please_choose_a_pict'))
        }

        // return false;
      },
      // 上传
      imgUpload(params) {
        var formData = new FormData()
        formData.append('file', params.file)
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200 && this.commentMedia.length < 9) {
            this.commentMedia.push({
              type: params.file.type.split('/')[0] == 'image' ? 'photo' : 'video',
              url: res.data[0]
            })
          }
        })
      },

      // 二级上传
      imgUploadEr(params) {
        var formData = new FormData()
        formData.append('file', params.file)
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200 && this.commentMediaEr.length < 9) {
            this.commentMediaEr.push({
              type: params.file.type.split('/')[0] == 'image' ? 'photo' : 'video',
              url: res.data[0]
            })
          }
        })
      },

      clickAd(id) {
        this.$http.adHitsIncr({
          id: id
        }).then(res => {
          // console.log(res)
        })
      },

      // 帖子内容过滤
      postContentFilter(val) {
        let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|:.]*[-A-Z0-9+&@#\/%=~_|])/ig
        val = val.replace(exp, "<a href='$1' target='_blank'><i class='ri-link-m'></i>Link</a>")
        let url = window.location.href.split('#')[0]
        let reg = /(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g
        val = val.replace(reg, `<a href='${url}#/search-result?keyword=$2'>$1#$2</a>`)
        val = val.replace(/(\r\n|\n|\r)/gm, '<br />')
        // val = val.replace(reg, `<a href='${url}#/search-result?keyword=$1'>$1</a>`)
        // val = val.replace(/keyword\=\s?\#/ig, 'keyword=').replace(/(\r\n|\n|\r)/gm, "<br />")
        // let idx = []
        // for (let i in val) {
        //   if (val[i] == '#') {
        //     idx.push(Number(i))
        //   }
        // }
        // let allAry = []
        // for (let i = 0; i < idx.length - 1; i++) {
        //   let newStr = val.slice(idx[i], (idx[i + 1]))
        //   let ele = newStr.replaceAll('#', '')
        //   let flag = ele.includes('<a') || ele.includes('a>')
        //   if (ele.trim().length && !flag) {
        //     allAry.push(ele)
        //   }
        // }

        // allAry.forEach(v => {
        //   val = val.replace(`#${v}#`, `<a href='${url}#/search-result?keyword=${v}'>#${v}#</a>`)
        // })
        return val
      },
      friendOrGroupPopup() {
        return `friendOrGroupPopup${this.post.id}`
      },
      // 分享到好友
      handleClickFriendsPopup(item) {
        this.shareData = item
        let { friendsList, groupList } = this.dataChat
        let list = JSON.parse(JSON.stringify([...friendsList, ...groupList]))
        list.forEach((v, i) => {
          this.$set(v, 'select', false)
        })
        this.friendAndGroup = list
        this.$bvModal.show(`friendOrGroupPopup${this.post.id}`)
      },
      // 移除选中
      removeSelect(item) {
        this.$set(item, 'select', false)
      },
      // 轮询发送消息
      async sendMessages() {
        this.allSuccess = []
        let list = this.selectedList
        for (let index = 0; index < list.length; index++) {
          if (list[index].user_id) {
            await this.shareFriend(list[index])
          } else if (list[index].group_id) {
            await this.sendGroup(list[index])
          }
        }
        // this.$bvModal.hide("friendOrGroupPopup");
        if (this.allSuccess.every(v => v)) {
          this.$message({
            message: this.$t('Posts.9ca@share_success'),
            type: 'success'
          })
        }
      },
      // 分享内容处理
      sharefilterParams(item) {
        let params = {}
        params.share_type = 9 // => 暂定 post
        params.share_msg = this.shareData
        return JSON.stringify(params)
      },
      // 分享给好友
      shareFriend(item) {
        return new Promise(async (resolve, reject) => {
          let content = this.sharefilterParams(item)
          const data = {
            push_user_id: item.user_id,
            msg_type: '10',
            content,
            client_id: this.dataChat.client_id
          }
          this.$http.sendChat(data).then((res) => {
            if (res.status == 200) {
              // this.$message({
              //   message: 'Share success！',
              //   type: 'success'
              // })
              this.allSuccess.push(true)
              this.$bvModal.hide(`friendOrGroupPopup${this.post.id}`)
            } else {
              this.allSuccess.push(false)
            }
            resolve()
          })
        })
      },
      // 分享到群
      sendGroup(item) {
        return new Promise(async (resolve, reject) => {
          let content = this.sharefilterParams(item)
          const data = {
            type: 'sendGroup',
            client_id: this.dataChat.client_id,
            msg_type: '10',
            group_id: item.group_id,
            content
          }
          this.$http.sendGroup(data).then((res) => {
            if (res.status == 200) {
              // this.$message({
              //   message: 'Share success！',
              //   type: 'success'
              // })
              this.allSuccess.push(true)
              this.$bvModal.hide(`friendOrGroupPopup${this.post.id}`)
            } else {
              this.allSuccess.push(false)
            }
            resolve()
          })
        })
      },
      /**
             *  4 => 活动
             *  3 => 课程
             *  2 => 会议
             *  5 => 项目
             *  7 => 奖项
             *  desc: 当前分享类型不为1时, 设置其他分享类型的图标及跳转
             */
      currentShareShowType(type, item) {
        // let imageURL = "";
        if (item.share_type == 2) {
          // imageURL = this.shareShowType.meetingImage;
          if (type === 'url') {
            this.$router.push(`/meeting-details?id=${item.share_id.id}&type=4`)
          }
        } else if (item.share_type == 4) {
          // imageURL = this.shareShowType.eventImage;
          if (type === 'url') {
            // this.$router.push(`/event-detail/${item.share_id.id}`)
            this.$router.push({
              path: '/event-detail',
              query: {
                id: item.share_id.id,
                type: 2
              }
            })
          }
        } else if (item.share_type == 3) {
          // imageURL = this.shareShowType.courseImage;
          if (type === 'url') {
            this.$router.push(`/course-detail?id=${item.share_id.id}&type=3`)
          }
        } else if (item.share_type == 5) {
          // imageURL = this.shareShowType.projectImage;
          if (type === 'url') {
            this.$router.push({
              path: '/project-detail',
              query: {
                id: item.share_id.id,
                type: 1
              }
            })
          }
        } else if (item.share_type == 61) {
          // imageURL = this.shareShowType.projectImage;
          if (type === 'url') {
            this.$router.push({
              path: '/organization-detail',
              query: {
                channel_id: item.share_id.channel_id
              }
            })
          }
        } else if (item.share_type == 7) {
          // imageURL = this.shareShowType.awardImage;
          if (type === 'url') {
            this.$router.push(`/award-detail?id=${item.share_id.id}`)
          }
        } else if (item.share_type == 8) {
          // imageURL = this.shareShowType.awardImage;
          if (type === 'url') {
            this.$router.push(`/shopscoredetails?id=${item.share_id.id}`)
          }
        }

        // return imageURL;
      },
      /**
             * --------帖子相关----------
             * */
      // 复制link
      copyPostLink(e) {
        this.$message.success(this.$t('Posts.510@copy_successfully'))
      },

      // getFriendList1 () {
      //   let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      //   this.$http
      //     .getUserFriendList({
      //       user_id: userInfo.id,
      //       type: 0
      //     })
      //     .then((res) => {
      //       res.data.forEach((item) => {
      //         this.$set(item, 'select', false)
      //       })

      //       this.friendsList = res.data
      //       // console.log(222, res.data);
      //     })
      // },

      // 点赞帖子
      likePost(post) {
        this.$http
          .likePost({
            user_post_id: post.id
          })
          .then((res) => {
            if (res.status == 200 && !post.user_post_like) {
              // 判断是点赞还是取消点赞
              post.like_total++
            } else if (post.user_post_like) {
              post.like_total--
            }
            this.$set(post, 'user_post_like', !post.user_post_like)
          })
      },

      checkLikePost(post) {
        // console.log('this.likePostShow', this.likePostShow);
        if (this.likePostShow) {
          this.likePostShow = false
        } else {
          this.likePostShow = true
          this.sharePostShow = false
          this.$set(post, 'isShowCommentList', false)
          post.firstCommentList = []
        }
      },
      checkSharePost(post) {
        // console.log('this.sharePost', this.sharePost);
        if (this.sharePostShow) {
          this.sharePostShow = false
        } else {
          this.sharePostShow = true
          this.likePostShow = false
          this.$set(post, 'isShowCommentList', false)
          post.firstCommentList = []
        }
      },
      // 获取顶级评论列表
      getFirstCommentList(post) {
        // console.log('post.isShowCommentList', post.isShowCommentList)
        if (!post.isShowCommentList) {
          this.commonPage = 1
          this.$set(post, 'isShowCommentList', true)
          this._getFirstCommentList(post, 1)
          this.sharePostShow = false
          this.likePostShow = false
        } else {
          this.$set(post, 'isShowCommentList', false)
          post.firstCommentList = []
        }
      },
      InfinitePostData(post) {
        this.$http
          .getPostCommentInfo({
            user_post_id: post.id,
            page: this.commonPage,
            size: 50
          })
          .then((res) => {
            if (res.data.data.length > 0) {
              res.data.data.forEach(item => {
                post.firstCommentList.push(item)
              })
            }
          })
      },

      // 获取顶级评论列表
      _getFirstCommentList(post, page) {
        this.firstCommentPage = 1
        this.$http
          .getPostCommentInfo({
            user_post_id: post.id,
            page: page || this.firstCommentPage,
            size: page ? 50 : this.$route.params.id ? 50 : 5
          })
          .then((res) => {
            // console.log("post", post);
            // 打开顶级评论列表+为列表赋值
            this.commonTotal = res.data.last_page
            this.$nextTick(() => {
              this.$set(post, 'firstCommentList', res.data.data)
            })
            this.$refs.commentRef.addEventListener('scroll', (e) => {
              const scrollTop = e.target.scrollTop // 滚动条滚动时，距离顶部的距离
              const windowHeight = e.target.clientHeight // 可视区的高度
              const scrollHeight = e.target.scrollHeight // 滚动条的总高度
              // console.log(scrollTop, windowHeight, scrollHeight)
              if (
                Math.ceil(scrollTop) + parseInt(windowHeight) === parseInt(scrollHeight)
              ) {
                this.commonPage++
                if (this.commonPage > this.commonTotal) return
                this.InfinitePostData(post)
              }
            })
            // if data为空，就改变文字，并且不能再请求
          })
      },

      // 分享
      shareStaus(post) {
        bus.$emit('share', post)
      },

      // 删除帖子
      deletePost(post) {
        this.$confirm(
          this.$t('Posts.184@this_operation_will_'),
          'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          this.$http
            .delUserPost({
              user_post_id: post.id
            })
            .then((res) => {
              if (res.status == 200) {
                this.$emit('refreshPostList', true)
                this.$bus.$emit('refreshPost')
              }
            })
        })
      },

      /**
             * 评论帖子--大评论框
             * parent-回复谁就是谁的id,评论帖子0
             * com_parent:顶级id，评论帖子时不存在
             * */
      commentPost(post) {
        // 评论内容格式化
        let imgTextContent = {}
        imgTextContent.content = this.commentPostContent
        imgTextContent.img = this.commentMedia

        this.$http
          .commentPost({
            type: this.computedPageType,
            user_post_id: post.id,
            parent: 0,
            content: JSON.stringify(imgTextContent),
            commet_parent: '' // 不能省
          })
          .then((res) => {
            if (res.status == 200) {
              post.firstCommentList.unshift(res.data)
              this.commentPostContent = ''
              // this.commentPostContent = ''
              this.commentMedia = []

              // console.log(post);
              let plus1 = post.comment_total + 1
              this.$set(post, 'comment_total', plus1)
            }
          })
      },

      // 点赞评论
      likePostComment(comment) {
        this.$http
          .likePostComment({
            user_post_comment_id: comment.id
          })
          .then((res) => {
            if (res.status == 200) {
              // 判断是点赞还是取消点赞
              comment.like_total++
            } else {
              comment.like_total--
            }
            this.$set(comment, 'is_like', !comment.is_like)
          })
      },

      // 打开二级评论弹框
      openReplayComment(comment) {
        this.firstCommentObj = comment
        this.$nextTick(() => {
          this.$bvModal.show(`commentForm${this.firstCommentObj.id}`)
          setTimeout(() => {
            let htmlContent = document.getElementById(`commentForm${this.firstCommentObj.id}___BV_modal_outer_`)
            // console.log('htmlContent',htmlContent);
            if (htmlContent) htmlContent.style.zIndex = '9999'
          }, 20)
        })
      },

      /**
             * textarea-modal回复评论--回复顶级
             * parent:回复谁，就是谁的id
             * com_parent:回复的评论的id
             * */
      replayPostComment(post) {
        if (!this.replay_textarea.trim() && !this.commentMediaEr.length) return this.$message.error(this.$t('Posts.f39@fail_to_comment'))
        // 评论内容格式化
        let imgTextContent = {}
        imgTextContent.content = this.replay_textarea
        imgTextContent.img = this.commentMediaEr
        // 顶级评论的信息传递
        let comment = this.firstCommentObj
        this.$http
          .commentPost({
            type: this.computedPageType,
            user_post_id: post.id,
            parent: comment.id, // 顶级评论的parent为0
            commet_parent: comment.id,
            content: JSON.stringify(imgTextContent)
          })
          .then((res) => {
            // 评论成功，显示'gmc等人共1条回复'
            // console.log(res);
            if (res.status == 200) {
              comment.comment_user_name = res.data.nick_name
              comment.comment_total++
              this.replay_textarea = ''
              this.commentMediaEr = []
              this.$bvModal.hide(this.repalyFormModalId)
              this.$set(post, 'comment_total', post.comment_total + 1)
            }
          })
      },

      // 删除评论
      delSelfPostComment(post, comment) {
        this.$confirm(
          this.$t('Posts.2f2@this_operation_will_'),
          'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          // console.log("comment", comment);
          // console.log("post", post);
          this.$http
            .delPostComment({
              user_post_comment_id: comment.id
            })
            .then((res) => {
              // 删除顶级列表的某一项
              this.$set(
                post,
                'firstCommentList',
                post.firstCommentList.filter((some) => some.id !== comment.id)
              )
              let subd_n = post.comment_total - comment.comment_total - 1
              this.$set(post, 'comment_total', subd_n)
            })
        })
      },

      /**
             * ----------二级评论列表弹框--------
             * */

      // 打开二级评论列表
      secondCommentOpen(commentInfo) {
        // 设置二级弹框列表的唯一性
        this.commentListParent = commentInfo
        // 打开弹框
        this.$nextTick(() => {
          this.$bvModal.show(`secondCommentListModal${commentInfo.id}`)
        })

        setTimeout(() => {
          let htmlContent = document.getElementById(`secondCommentListModal${commentInfo.id}___BV_modal_outer_`)
          // console.log('htmlContent',htmlContent);
          if (htmlContent) htmlContent.style.zIndex = '9999'
        }, 20)

        // 获取二级评论列表
        this.$http
          .getChildComment({
            user_post_comment_id: commentInfo.id
          })
          .then((res) => {
            // 列表
            this.secondCommentList = res.data.children_commet.data
            this.secondCommentList.forEach((item) => {
              this.$set(item, 'isShowReplayForm', false)
              this.$set(item, 'replayForm', '')
            })

            // console.log(commentInfo);
            // 父级
            this.parentcomment = commentInfo
            this.$set(this.parentcomment, 'isShowReplayForm', false)
            this.$set(this.parentcomment, 'replayForm', '')
            this.$set(this.parentcomment, 'childresComment', [])
            // console.log("get", res);
          })
      },

      // 在弹框上回复评论---“回复”按钮
      replayModalComment(postId, comment, commentContent, post, flag) {
        // 两种情况判断--回复顶级评论&回复二级评论
        // 回复顶级评论：parent为回复的评论的id，comment_parent为顶级评论id（comment.id）
        // console.log('comment', comment);

        // 评论内容格式化
        let imgTextContent = {}
        imgTextContent.content = commentContent
        // imgTextContent.img = [];
        this.$http
          .commentPost({
            type: this.computedPageType,
            user_post_id: postId,
            parent: comment.id,
            commet_parent: comment.commet_parent
              ? comment.commet_parent
              : comment.id,
            content: JSON.stringify(imgTextContent)
          })
          .then((res) => {
            // console.log(res);
            if (res.status == 200) {
              // console.log(res);
              // 给自制二级评论添加两个变量控制回复框的显示和值
              this.$set(res.data, 'isShowReplayForm', false)
              this.$set(res.data, 'replayForm', '')

              this.secondCommentList.unshift(res.data)

              // 隐藏回复框和清空
              comment.replayForm = ''
              comment.isShowReplayForm = false
              post.comment_total++
              if (flag) {
                this.parentcomment.comment_total++
              } else {
                comment.comment_total++
              }
            }
          })
      },

      // 删除modal评论
      deleteModalComment(second, post) {
        // console.log(second);
        this.$confirm(
          this.$t('Posts.2f2@this_operation_will_'),
          'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          this.$http
            .delPostComment({
              user_post_comment_id: second.id
            })
            .then((res) => {
              this.secondCommentList = this.secondCommentList.filter(
                (item) => item.id != second.id
              )
              let subd_n = post.comment_total - 1
              this.parentcomment.comment_total--
              this.$set(post, 'comment_total', subd_n)
            })
        })
      },

      /**
             * ---------帖子详情--------
             * */

      getPostDetail(post, image, index) {
        // console.log(6666666666666, image)
        if (image.type == 'photo') {
          this.$http
            .postDetail({
              user_post_id: post.info_url
            })
            .then((res) => {
              this.isShowSelfModal = true
              // console.log(res);
              this.postDetail = res.data
              this.mediaIndex = index
              this._getFirstCommentList(post, 1)
            })
        }
      },

      // 关掉自制modal
      closeSelfModal() {
        this.isShowSelfModal = false
      },

      // complain
      getShareInfo(post) {
        this.$bvModal.show(`complainModalId${post.id}`)
        this.$http
          .getPostInfo({
            user_post_id: post.info_url
          })
          .then((res) => {
            // console.log("com", res);
            this.isHasImg = res.data.media.length
            this.compalinInfo = res.data
            if (this.isHasImg) {
              this.compalinInfoType = res.data.media[0].type
              this.compalinInfoeUrl = res.data.media[0].url
            }
          })
      },

      choosedOneComplain(index) {
        this.chooseComplainType = index
      },

      complainPost(post) {
        this.$http
          .complain({
            model_type: 1,
            model_id: post.id,
            type: this.chooseComplainType + 1,
            content: this.describe_textarea
          })
          .then((res) => {
            // console.log(res);
            if (res.status == 200) {
              this.$bvModal.hide(`complainModalId${post.id}`)
              this.describe_textarea = ''
            }
          })
      },

      toPostDetail(post) {
        this.$emit('SharePostDetail', post)
      },

      overViewPicture(post) {
        // console.log(post)
        // 判斷是帖子的查看圖片還是評論的
        if (typeof (post) === 'string') {
          // 評論的圖片
          let media = JSON.parse(post).img
          this.viewPictureImg = media
        } else {
          // 帖子的圖片
          this.viewPictureImg = post.media
        }
        this.isShowViewPicture = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 768px) and (max-width: 991px) {
    ::v-deep .post_media_list {
      .four_img:nth-child(2) {
        margin-right: 120px !important;
      }
    }

    ::v-deep .share_link {
      .four_share:nth-child(2) {
        margin-right: 180px !important;
      }
    }

    ::v-deep .share_link {
      .common_share:nth-child(3) {
        margin-right: 50px !important;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1365px) {
    ::v-deep .share_link {
      .four_share:nth-child(2) {
        margin-right: 30px !important;
      }
    }
  }

  @media screen and (min-width: 992px) {
    ::v-deep .friendOrGroupPopup {
      .modal-dialog {
        max-width: 650px !important;
      }
    }
  }

  ::v-deep .friendOrGroupPopup {
    .textOverflow {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .modal-dialog {
      .modal-content {
        min-height: 520px !important;

        .modal-body {
          padding: 0;

          .contentLeft {
            padding: 1rem;
            padding-left: 22px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }
          }

          .contentRight {
            padding: 1rem;
            padding-right: 30px;
            height: 460px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }

            .title {
              font-size: 14px;
              color: #666666;
              margin-bottom: 30px;
              text-align: center;
            }
          }

          .imgBox {
            min-width: 30px;
            min-height: 30px;
          }
        }
      }
    }
  }

  // .user-post-data {
  //   .user-img {
  //     width: 60px;
  //     height: 60px;
  //     background: url("../../../assets/images/public/user.png");
  //     background-size: 100%;
  //     cursor: pointer;
  //     > img {
  //       display: block;
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //     }
  //   }
  // }

  .user_post_content {
    >div {

      ul,
      li,
      p {
        display: inline-block;
        word-break: break-word;
      }
    }

    .ad_content {
      word-break: break-word;

      ::v-deep p {
        margin-bottom: 0;
      }
      ::v-deep a{
        color: #50b5ff !important;
      }
    }
  }

  .rightTopBtn {
    height: 100px;
    position: relative;
    overflow: hidden;

    /* img {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100px;
      height: 100px;
      object-fit: cover;
      display: block;
    } */

    .typeBtn {
      padding: 0 10px;
      height: 25px;
      background: #dddddd;
      border-radius: 0px 0px 0px 5px;
      font-size: 12px;
      color: #ffffff;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  ::v-deep.post_media_list {
    width: 100%;

    display: flex;
    flex-wrap: wrap;

    
    >.media_item {
      /* padding-bottom: 25%; */
      overflow: hidden;
      border-radius: 8px;
      margin-top: 10px;
      margin-right: 10px;
      position: relative;

      >.el-image {
        background: #eee;
      }

      >.el-image,
      video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* position: absolute; */
        /* top: 0; */
        /* left: 0; */

        >.image-slot {
          position: absolute;
          top: 40%;
          left: 35%;
        }
      }

      &.img_cover::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }

      &.img_cover:hover {
        cursor: pointer;

        &::before {
          opacity: 1;
        }
      }

      /* >#photo-grid {
        width: 100%;
      } */
    }

    .one_img {
      text-align: left;

      img,
      video {
        width: auto;
        height: auto;
        max-width: 100% !important;
        max-height: 500px;
      }
    }
  }

  // 图片缩略图
  ul.simple_img>li {
    color: #50b5ff;
    cursor: pointer;

    &:hover {
      &>span {
        text-decoration: underline;
      }
    }
  }

  .share_link {
    /* padding: 15px; */
    border: 1px solid #f1f1f1;
    background-color: #f9f9f9;
    border-radius: 5px;
    margin-top: 22px;

    .share_avatar {
      >div {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      >h3 {
        font-size: 16px;
        padding-left: 10px;
        color: #333;
      }
    }

    .media_box {
      display: flex;
      flex-wrap: wrap;

      .common_share,
      .four_share {
        width: 128px;
        height: 128px;
      }

      >li {
        /* width: 25%; */
        /* padding-bottom: 25%; */
        border-radius: 8px;
        overflow: hidden;
        margin-top: 5px;
        margin-right: 10px;
        position: relative;

        >img,
        video {
          /* position: absolute; */
          /* top: 0; */
          /* left: 0; */
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.img_box::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 99;
        }

        &.img_box:hover {
          cursor: pointer;

          &::before {
            opacity: 1;
          }
        }
      }

      .one_share {
        text-align: left;

        img,
        video {
          width: auto;
          height: auto;
          max-width: 100% !important;
          max-height: 360px;
        }
      }

      .four_share:nth-child(2) {
        margin-right: 130px;
      }
    }

    >.share_icon_list {
      >p {
        color: #666;
        font-size: 14px;
      }

      >ul>li {
        color: #666;
        cursor: pointer;
        margin-left: 1rem;

        >i {
          font-size: 14px;
          padding: 5px;
          margin-right: 5px;

          &:hover {
            background-color: rgb(204, 229, 245);
            border-radius: 50%;
            color: #50b5ff;
          }

          &:hover+span {
            color: #50b5ff;
          }
        }
      }
    }
  }

  .original_post_del {
    padding: 15px;
    border: 1px solid #f1f1f1;
    background-color: #f8f8f8;
  }

  // like_comment_share
  .like_comment_share {
    display: flex;
    justify-content: space-between;
    color: #999;

    li {
      display: flex;
      align-items: center;
      // width: 33.3333%;
      margin: 5px 5px 5px 0;

      >i {
        cursor: pointer;
        font-size: 18px;
        padding: 0 5px;

        &:hover {
          background-color: rgb(204, 229, 245);
          border-radius: 50%;
          color: #50b5ff;
        }

        &:hover+span {
          color: #50b5ff;
        }
      }

      &:nth-child(2) {
        justify-content: center;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }

    .bg_gray {
      span {
        // display: block;
        padding: 0px 8px;
        border-radius: 12px;
        cursor: pointer;
        line-height: 26px;

        &:hover {
          background-color: #F6F6F6;
        }
      }
    }
  }

  .user_avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #eee;
  }

  .comment_and_replay {
    &::-webkit-scrollbar-thumb {
      background-color: #B9E1FF !important;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
    }

    >div {
      margin-bottom: 10px;

      .user_avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #eee;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .comment_content {
        width: calc(100% - 35px);

        .comment_main {
          >span:first-child {
            color: #50b5ff;
            cursor: pointer;
          }
        }

        .time_icon {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 12px;
            color: #999;
          }

          >ul {
            display: flex;
            font-size: 18px;

            &:hover {
              &>li:first-child {
                opacity: 1;
              }
            }

            >li {
              margin-left: 10px;
              padding: 0 5px;
              border-radius: 50%;
              cursor: pointer;

              &:first-child {
                opacity: 0;
              }

              >i {
                padding: 5px;
                border-radius: 50%;

                &:hover {
                  background-color: rgb(204, 229, 245);
                  color: #50b5ff;
                }
              }
            }
          }
        }

        .second_comment {
          display: flex;
          padding-left: 10px;
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 3px;
            height: 60%;
            background-color: #f0f0f0;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }

          >span {
            color: #50b5ff;
            cursor: pointer;
          }
        }

        .replay_white_box {
          background: #f0f0f0;
          padding: 10px;
          justify-content: flex-end;

          >input {
            width: 100%;
          }

          >div {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .comment_and_replay>li {
    margin-bottom: 10px;

    .user_avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #eee;

      >img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .comment_content {
      width: calc(100% - 35px);

      .comment_main {
        >span:first-child {
          color: #50b5ff;
          cursor: pointer;
        }
      }

      .time_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 12px;
          color: #999;
        }

        >ul {
          display: flex;
          font-size: 18px;

          &:hover {
            &>li:first-child {
              opacity: 1;
            }
          }

          >li {
            margin-left: 10px;
            padding: 0 5px;
            border-radius: 50%;
            cursor: pointer;

            &:first-child {
              opacity: 0;
            }

            >i {
              padding: 5px;
              border-radius: 50%;

              &:hover {
                background-color: rgb(204, 229, 245);
                color: #50b5ff;
              }
            }
          }
        }
      }

      .replay_white_box {
        background: #f0f0f0;
        padding: 10px;
        justify-content: flex-end;

        >input {
          width: 100%;
        }

        >div {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .second_comment {
      display: flex;
      padding-left: 55px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 3px;
        height: 60%;
        background-color: #f0f0f0;
        position: absolute;
        top: 50%;
        left: 43px;
        transform: translateY(-50%);
      }

      >span {
        color: #50b5ff;
        cursor: pointer;
      }
    }

    .second_time {
      padding-left: 55px;

      // li hover是显示icon
      &:hover .second_icon {
        opacity: 1;
      }

      span {
        font-size: 12px;
      }

      >ul {
        display: flex;
        opacity: 0;
        font-size: 18px;

        >li {
          margin-left: 10px;
          padding: 0 5px;
          border-radius: 50%;
          cursor: pointer;

          >i {
            padding: 5px;
            border-radius: 50%;

            &:hover {
              background-color: rgb(204, 229, 245);
              color: #50b5ff;
              // opacity: 1;
            }
          }
        }
      }
    }

    .left_padding_white_box {
      background: #f0f0f0;
      padding: 10px;
      margin-left: 55px;
      justify-content: flex-end;

      >input {
        width: 100%;
      }

      >div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .all_comment {
    text-align: center;
    padding-bottom: 15px;
    cursor: pointer;

    &:hover>span {
      color: #50b5ff;
    }
  }

  #photo-grid {
    width: 100%;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .ad-flag {
    /* display: block; */
    padding: 0 15px;
    height: 25px;
    border: 1px solid #83681d;
    text-align: center;
    line-height: 25px;
    color: #83681d;
    border-radius: 6px;
    background: #edbd37;
  }

  .complain_box {
    >h3 {
      color: #333;
      margin: 10px 0;
      font-size: 14px;
    }

    >.complain_content {
      display: flex;
      background-color: #f4f5f6;
      border: 1px solid #f1f1f1;

      >.img_box {
        width: 80px;
        height: 80px;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .share_content {
        padding: 2px 5px;

        >p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }

    >.radia_choose {
      display: flex;
      flex-wrap: wrap;

      >li {
        width: calc(33.3333% - 14px);
        height: 45px;
        background-color: #f4f5f6;
        color: #333;
        margin-right: 14px;
        margin-top: 16px;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
      }
    }
  }

  .media_box {
    display: flex;
    flex-wrap: wrap;

    >.li_pic {
      width: 69px;
      height: 69px;
      margin-right: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
      overflow: hidden;
      border-radius: 8px;
      position: relative;

      >img,
      >video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; //图片自适应的样式
      }

      .close_img {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }

  .violation_post {
    display: flex;

    p {
      width: 100%;
      white-space: nowrap;
      padding: 13px;
      background: #fff5f4;
      border-radius: 5px;
      color: #ff9b8a;
    }
  }

  .user_post_type {
    margin-left: 8px;
    font-size: 14px;
    color: #999999;

    div {
      display: inline-block;
      cursor: pointer;
      height: 20px;
      vertical-align: bottom;
      margin: 0;
      margin-left: 3px;
      color: #333;
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 600px) {
    .four_img {
      width: 160px;
      height: 160px;
    }
    .common_img {
      width: 160px;
      height: 160px;
    }

    .four_img:nth-child(2) {
      margin-right: 50px;
    }
  }
  @media (max-width: 600px) {
    .pcText{
      display: none;
    }
    .common_img {
      width: 120px;
      height: 120px;
    }
    .four_img {
      width: 90px;
      height: 90px;
    }

    .four_img:nth-child(2) {
      margin-right: 30px;
    }
  }
</style>