<template>
  <div class="content" ref="content" v-show="likePostList.length">
    <div class="d-flex align-items-top" v-for="item of likePostList" :key="item.id" style="margin-bottom: 10px;">
      <div class="user_avatar mr-2">
        <!-- <img :src="item.avatar" alt="" /> -->
        <el-avatar :size="35" :src="item.avatar" @error="true">
          <img src="@/assets/images/public/user.png" />
        </el-avatar>
      </div>
      <div>
        <div style="color: #50b5ff; font-size: 14px">
          <span>{{ item.nick_name }}</span>
        </div>
        <div style="color: #a09e9e">
          <span>like this post</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      id: {
        type: Number,
      },
    },
    data() {
      return {
        likePostList: [],
        likePage: 1,
        likeTotal: 1,
      };
    },
    mounted() {
      this.getLikePostList();
    },
    methods: {
      lazyLoading(e) {
        const scrollTop = e.target.scrollTop; // 滚动条滚动时，距离顶部的距离
        const windowHeight = e.target.clientHeight; // 可视区的高度
        const scrollHeight = e.target.scrollHeight; // 滚动条的总高度
        if (
          parseInt(scrollTop) + parseInt(windowHeight) === parseInt(scrollHeight)
        ) {
          this.likePage++;
          if (this.likePage > this.likeTotal) return;
          this.getLikePostList();
        }
      },
      getLikePostList() {
        this.$refs.content.addEventListener("scroll", this.lazyLoading);
        this.$http
          .getPostLike({
            post_id: this.id,
            page: this.likePage,
            per_page: 20,
          })
          .then((res) => {
            if (res.status == 200) {
              res.data.data.forEach((item) => {
                this.likePostList.push(item);
              });
              this.likeTotal = res.data.last_page || 1;
            }
          });
      },
    },
  };
</script>
<style lang="less" scoped>
  .content {
    max-height: 300px;
    // overflow-y: scroll;
    overflow: auto;
    border-top: 1px solid #F1F1F1;
    padding-top: 20px;
    margin-top: 5px;

    &::-webkit-scrollbar-thumb {
      background-color: #B9E1FF !important;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff !important;
    }

    .user_avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #eee;

      >img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
</style>